<template>
  <v-menu offset-y v-model="helpMenuState">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text tile color="iconsHeader" class="sm-btn-toolbar"
      data-testid="circle-header-icon" v-bind="attrs"
        v-on="on">
        <v-icon>mdi-help-circle-outline</v-icon>
      </v-btn>
    </template>
    <v-list dense class="d-flex flex-column mt-2 pa-0">
      <v-list-item @click="openOSS()" class="py-1 px-2">
        <v-icon class="pr-3">mdi-open-source-initiative</v-icon>
        <v-list-item-title>{{ $t('oss') }}</v-list-item-title>
      </v-list-item>
      <v-list-item @click="openReleaseNotesDialog()" class="py-1 px-2">
        <v-icon class="pr-3">mdi-text-box-outline</v-icon>
        <v-list-item-title>{{ $t('ems_release_note') }}</v-list-item-title>
      </v-list-item>
    </v-list>
    <v-divider class="divider" />
    <EMContactInfo/>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EMContactInfo from './EMContactInfo.vue';

export default {
  name: 'EMHelpMenu',
  components: {
    EMContactInfo,
  },
  data() {
    return {
      systemVersion: process.env.VUE_APP_EMS_CURRENT_VERSION,
    };
  },
  computed: {
    ...mapGetters(['getHelpMenuState']),
    helpMenuState: {
      get() {
        return this.getHelpMenuState;
      },
      set(value) {
        this.toggleHelpMenu(value);
      },
    },
  },
  methods: {
    ...mapActions(['toggleDialog', 'getCurrentVersionReleaseNotes', 'toggleHelpMenu']),
    openOSS() {
      window.open('/LicenseInfo-EcoMAIN_6.3.html', '', `width=${window.innerWidth - 400},height=${window.innerHeight - 100},left=${200 + window.screenX},top=${75 + window.screenY}`);
    },
    async openReleaseNotesDialog() {
      await this.getCurrentVersionReleaseNotes();
      this.toggleDialog('releaseNotes');
    },
    showUserManual() { },
  },
};
</script>

<style>
.divider {
  background-color: var(--v-buttons-base);
  height: 1.5px;
  max-height: 1.5px;
}
.sm-btn-toolbar {
  height: 36px;
  min-width: 36px !important;
  padding: 0px 5px !important;
}
</style>
