<template>
  <div class="contact">
    <v-list dense class="d-flex flex-column pa-0">
      <v-list-item dense class="py-1 px-2">
        <v-icon class="pr-3">mdi-email-outline</v-icon>
        <v-list-item-title>{{ $t('contact') }}</v-list-item-title>
      </v-list-item>
    </v-list>
    <v-card flat class="pa-3 ">
      <v-row align="center" justify="start">
        <v-col class="email">
          {{ email }}
        </v-col>
        <v-col cols="auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="copy-icon"
              :class="{'icon-animation': copied}" v-bind="attrs" v-on="on"
              @click="copyToClipboard">
                {{ copied ? 'mdi-check' : 'mdi-content-copy' }}
              </v-icon>
            </template>
            <span>{{ $t('copy_email') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'EMContactInfo',
  data() {
    return {
      email: 'customer.care.digital@siemens-energy.com',
      copied: false,
    };
  },
  methods: {
    copyToClipboard() {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(this.email).then(() => {
          this.copied = true;
          setTimeout(() => { this.copied = false; }, 2000);
        });
      }
    },
  },
};
</script>

<style scoped>
.contact {
  background-color: white;
}

.email {
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1rem;
}
.copy-icon {
  cursor: pointer;
}

.icon-animation {
  color: green;
  animation: bounce 0.5s;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
</style>
